.proyects{
    background-color: rgb(0, 0, 0);
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;
    


    h3{
        font-size: 2.5rem;
        font-weight: bold;
        text-transform: uppercase;
        color: white;
    }

    .proyects-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        width:100%;
        padding-top: 3rem;
        margin-bottom: 3rem;
    }

    @media (max-width: 866px) {
        .proyects{
            padding: 5rem 0;

            h3{
                font-size: 2rem; 
                margin-bottom: 2rem;
            }
            .proyects-container{
                padding: 0;
            }
        }
    }
}
