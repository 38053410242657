body {
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  color: rgb(68,70,73);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  background-image: url(
    https://i.pinimg.com/originals/a9/6e/30/a96e30788d743dee8b7c8b0b55832b80.gif
    );
    /* https://pa1.narvii.com/6897/fbbd7dab3dedd6e0c72d142939606e77f0e685dfr1-455-262_00.gif */
    /* https://media1.tenor.com/images/9d439f6476ea633d9ad1d03fb156e291/tenor.gif?itemid=6194347 */
  /* https://thumbs.gfycat.com/TheseHardAntelopegroundsquirrel-poster.jpg */
  /* background-repeat: no-repeat;
  background-size: contain; */
}


