.footer{
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    p{
        color:white;
        margin: 1rem;
    }

    svg{
        color:white;
        margin: 1rem;
    }

    @media (max-width: 650px) {
        p{
            font-size: .8rem; 
        }

    }
}