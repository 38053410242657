.about{
    background-color: rgb(255, 255, 255);
    min-height: 80vh;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 2rem;
    
    h3{
        font-size: 2.5rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 3rem;
    }

    .about-wrapper{
        width: 100%;
        max-width: 1350px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        margin-bottom: 2rem;

        .about-division{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: flex-start;

            .about-circulo{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                margin:.5rem;

                h5{
                    font-weight: bold;
                }
                p{
                    text-align: center;
                    
                }

                .about-icons{
                    background-color: rgb(45,194,201);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding:1rem;
                    margin-bottom: 1rem;

                    svg{
                        font-size: 4rem;
                        color: white;
                    }
                }
            }
        }
    }

    .about-container{
        width: 100%;
        max-width: 1350px;
        display: flex;
        flex-direction: row;         
        justify-content: space-between;
        align-items: flex-start;
        padding: 0;
        margin: 0;

        .container-txt{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-start;
            margin: 1rem;

            h5{
                font-weight: bold;
                margin-bottom: 1rem;
            }

            p{
                text-align: start;
                color: rgb(68,70,73);
                width: 100%;
            }
        }

        .listado{
            width: 100%;
            height: 100%;
            display: block;
            margin: 1rem;
            
            h5{
                text-align: start;
                font-weight: bold;
                margin-bottom: 1rem;
                
            }
    
            .tech-list {
            // text-transform: uppercase;
            width: 100%;
            list-style: none;
            font-size: 1rem;
            font-weight: 500;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-bottom: 1rem;
            // margin:.5rem;
            }

            .tech-list li {
            padding: 0 6px;
            min-width:86px;
            color: rgb(68,70,73);
            margin-bottom: .5rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            user-select: none; 

                svg {
                    font-size: 1rem;
                    margin: 0;
                    animation: donutfade 1s;
                }
                    @keyframes donutfade {
                    /* this applies to the whole svg item wrapper */
                        0% {
                            opacity: .2;
                        }
                        100% {
                            opacity: 1;
                        }
                    }
                
                .donut-ring {
                    stroke: #EBEBEB;
                }
                
                .donut-segment {
                    transform-origin: center;
                    stroke: rgb(45,194,201);
                }

                .donut-percent {
                    display: block;
                    font-size: 0.5em;
                    line-height: 1;
                    transform: translateY(0.5em);
                    font-weight: bold;
                }

  //Circulo 1              
                .donut-segment-1 {
                    stroke: rgb(45,194,201);
                }
                
                    @keyframes donut1 {
                        0% {
                            stroke-dasharray: 0, 100;
                        }
                        100% {
                            stroke-dasharray: 90, 10;
                        }
                    }
                :hover{

                    .donut-segment-1 {
                        animation: donut7 1s;
                    }
                    .donut-percent {
                        display: block;
                    }

                }
//Circulo 2

                
                    @keyframes donut2 {
                        0% {
                            stroke-dasharray: 0, 100;
                        }
                        100% {
                            stroke-dasharray: 80, 20;
                        }
                    }
                :hover{
                    .donut-segment-2 {
                        animation: donut3 2s;
                    }

                }
//circulo 3

                
                    @keyframes donut3 {
                        0% {
                            stroke-dasharray: 0, 100;
                        }
                        100% {
                            stroke-dasharray: 80, 20;
                        }
                    }
                :hover{
                    .donut-segment-3 {
                        animation: donut2 2s;
                    }
                }
//circulo 4

                    @keyframes donut4 {
                        0% {
                            stroke-dasharray: 0, 100;
                        }
                        100% {
                            stroke-dasharray: 70, 30;
                        }
                    }
                    :hover{
                        .donut-segment-4 {
                        animation: donut5 2s;
                        }
                    }
//circulo 5

                
                    @keyframes donut5 {
                        0% {
                            stroke-dasharray: 0, 100;
                        }
                        100% {
                            stroke-dasharray: 70, 30;
                        }
                    }
                :hover{
                    .donut-segment-5 {
                        animation: donut4 2s;
                    }

                }
//circulo 6

                
                    @keyframes donut6 {
                        0% {
                            stroke-dasharray: 0, 100;
                        }
                        100% {
                            stroke-dasharray: 90, 10;
                        }
                    }
                :hover{
                    .donut-segment-6 {
                        animation: donut7 2s;
                    }

                }
//circulo 7

                
                    @keyframes donut7 {
                        0% {
                            stroke-dasharray: 0, 100;
                        }
                        100% {
                            stroke-dasharray: 90, 10;
                        }
                    }
                :hover{
                    .donut-segment-7 {
                        animation: donut6 2s;
                    }

                }
                
            }

            ul {
            display: block;
            list-style-type: disc;
            margin-block-start: 0px;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 0px;
            }
        }
        
    }

    @media (max-width: 866px) {
        h3{
            font-size: 2rem; 
            margin-bottom: 2rem;
        }

        .about-wrapper{
            flex-direction: column;
            align-items: center;

            .about-division{
                .about-circulo{
                    h5{
                        font-size: 1rem;
                    }
                    .about-icons{
                        svg{
                            font-size: 2rem;
                        }
                    }
                }
            }
        }

        .about-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            width:100%;
            margin: 0;

            .container-txt{
                margin: 0;
                align-items: center;

                p{
                    text-align: center;
                }
            }

            .listado{

                h5{
                    text-align: center;
                }

                .tech-list{
                    justify-content: center;
                    align-items: flex-start;
                }
            }
        }
    }

}