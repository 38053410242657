.contact{
    background-color: rgb(255, 255, 255);
    min-height: 80vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 2rem;

    h3{
        font-size: 2.5rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 3rem;
    }

    h4{
        font-size: 2rem;
        margin-bottom: 3rem;
        text-align: center;
    }

    .contact-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 3rem;

        .contact-wrapper{
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            h6{
                font-weight: bold;
            }

            p{
                font-weight: lighter;
                text-align: center;
            }

            a{
                color: black;   
            }

            svg{
                font-size: 4rem;
                margin-bottom: 1rem;
                color: rgb(45,194,201)
            }

        }
    }

    @media (max-width: 800px) {
        h3{
            font-size: 2rem; 
            margin-bottom: 2rem;
        }

        h4{
            font-size: 1.5rem; 
        }
        .contact-container{
            flex-direction: column;
            .contact-wrapper{
                width: 100%;
            }
        }
    }

}

