.proyecto{
    width: 400px;
    height: 250px;
    margin: .2rem;

    .img-container {
        width: 100%;
        height: 100%;
        max-width: 400px;
        max-height: 250px;
        background: #fff;
        position: relative;
        overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                max-width: 450px;
                max-height: 250px;
                z-index: 1;
            }
    }
      
      .img-container:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgb(255, 255, 255);
        opacity: 0;
        transition: opacity .3s ease;  
        -webkit-transition: opacity 500ms;
        -moz-transition: opacity 500ms;
        -o-transition: opacity 500ms;
        -ms-transition: opacity 500ms;
        transition: opacity 500ms;  
      }
      
      .img-container:hover:before { 
        opacity: 1;
      }
      
      .img-container .text-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center; 
        width: 100%;
        height: 100%;
        padding: 10px 15px;
        position: absolute;
        top: 100%;
        
        h5{
            color: rgb(68,70,73);
            font-weight: bold;
            text-align: center;
        }

        h6{
            color: rgb(45,194,201);
            font-weight: bold;
            text-align: center;
        }

        p{
            color: rgb(68,70,73);
            font-weight: lighter;
            text-align: center;
            font-style: italic;
        }

        a{
            text-transform: uppercase;
            color: rgb(68,70,73);
            font-weight: bold;
            padding: .8rem 1.6rem;
            border: 2px solid rgb(45,194,201);
        }
        a:hover{
            background-color: rgb(45,194,201);
            color: white;
        }
      }
      
      .img-container:hover .text-container {    
        top: 0;
      }
}
    @media (max-width: 990px) {

        .proyecto{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h4{
                font-size: 1.5rem; 
            }
            h6{
                font-size: .9rem; 
            }
            p{
                font-size: .9rem; 
            }
            .container-text{
                width: 100%;
                margin-right: 0;
                margin-bottom: 2rem;
            }
            .img-container{
                width: 100%;
                align-items: flex-start; 
                img{
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 866px) {
        .proyecto {
            // width: 180px;
            // height: 120px;
            a{
                font-size: .8rem;
            }
        }
    }

    @media (max-width: 430px) {
        .proyecto {
            .container-text{
                .proyecto-botones{
                    flex-direction: column;
                    align-items: flex-start;
                    a{
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
