.total{
    height: 100%;
}
.loading{

    position:absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    /* left:50%;
    top:50%; */
    z-index:999;
    display: flex;
    align-items: center;
    justify-content: center;
}