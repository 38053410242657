.main{
    /* background-image: url(https://i.pinimg.com/originals/af/6e/da/af6eda73e8ef3f3c2eba1ee56de6d745.gif);
    https://www.jakpost.travel/wimages/large/109-1090782_black-and-pink-abstract-wallpaper-imvu-black-pink.jpg 
    https://static1.squarespace.com/static/5349e8b2e4b0c1a4ea562181/57b600a2579fb32986dc94bf/5ca426e0b208fce608801432/1563563404894/619b1ab7ac25a95f087e3df19637385c.gif?format=1500w
    https://media1.tenor.com/images/d7257395db5c0b4128dfedbeb80e0e2b/tenor.gif?itemid=4946447
    */
    /* background-image: url(https://media1.giphy.com/media/DfYlKozSP9OCs/source.gif); */
    // background-color: rgb(0, 0, 0);
    min-height: 100vh;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
    .main-wrapper{
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    h1, h2, a{
        color: rgb(255, 255, 255);
    }
    
    h1{
        
        font-size: 3.5rem;
        // font-weight: bolder;

        
        span{
            // font-family: 'Monoton', cursive;
            color: rgb(45,194,201);
        }

    }
    
    
    h2{
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .botones{
        width: 50%;
        display: flex;
        align-items: flex-start;

        .cta-btn--hero {
            background-color: rgba(0, 0, 0, 0.1);
            border: 1.5px solid transparent;
            -o-border-image: linear-gradient(135deg,#ffffff,#ffffff);
            border-image: linear-gradient(135deg,#ffffff,#ffffff);
            border-image-slice: 1;
            color: white;
        }
        .cta-btn {
            display: inline-block;
            position: relative;
            padding: .8rem 1.6rem;
            font-weight: 500;
            line-height: 0;
            z-index: 1;
            transition: all .6s cubic-bezier(.19,1,.22,1);
        }

        .cta-btn--hero:hover{
            background-color: white;
            color: black;
        }
    

    }


    
    
    @media (max-width: 1000px) {
        h1 {
            font-size: 3rem;
        }
    
        h2 {
            font-size: 1.8rem;
        }
        a{
            font-size: 1rem;
        }
    }
    
    @media (max-width: 650px) {
        h1 {
            font-size: 2rem;
        }
        h2 {
            font-size: 1.5rem;
        }

        .mar-right{
            margin-right: 1rem;
        }
        a{
            font-size: 1rem;  
        }

    }
}

